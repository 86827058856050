.contact-us-btn{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}


.dm-sans-font{
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: <weight>; */
    font-style: normal;
  }


  
.noto-sans-font {
    font-family: "Noto Sans", sans-serif;
    /* font-optical-sizing: auto; */
    /* font-weight: <weight>; */
    font-style: normal;
  }