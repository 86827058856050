@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotate-planet-hs {
    animation: rotate 120s linear infinite;
}

.glitch {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 150px;
  }
  .glitch:hover {
    text-shadow: 0.05em 0 0 #ec2225, -0.025em -0.05em 0 #313f97,
      0.025em 0.05em 0 #50c878;
      color: rgba(0, 194, 203, 0.2);
  }
  .glitch:before,
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
  .glitch:hover::before {
    animation: glitch 650ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
    transform: translate(-0.025em, -0.0125em);
  }
  .glitch:hover::after {
    animation: glitch 375ms infinite;
    clip-path: polygon(0 65%, 100% 20%, 100% 100%, 0 70%);
    transform: translate(0.0125em, 0.025em);
  }
  @keyframes glitch {
    0% {
      color: rgba(236, 34, 37, 0.2);
      text-shadow: 0.05em 0 0 #ec2225, -0.025em -0.05em 0 #313f97,
        0.025em 0.05em 0 #50c878;
    }
    14% {
      text-shadow: 0.05em 0 0 #ec2225, -0.025em -0.05em 0 #313f97,
        0.025em 0.05em 0 #50c878;
    }
    15% {
      color: #50c878;
      text-shadow: -0.05em -0.025em 0 #ec2225, 0.025em -0.025em 0 #313f97,
        -0.05em -0.05em 0 #50c878;
    }
    49% {
      text-shadow: -0.05em -0.025em 0 #ec2225, 0.025em -0.025em 0 #313f97,
        -0.05em -0.05em 0 #50c878;
    }
    50% {
      text-shadow: 0.025em 0.05em 0 #ec2225, -0.025em 0.05em 0 #313f97,
        0 -0.05em 0 #50c878;
    }
    99% {
      color: #313f97;
      text-shadow: 0.025em 0.05em 0 #ec2225, -0.025em 0.05em 0 #313f97,
        0 -0.05em 0 #50c878;
    }
    100% {
      text-shadow: -0.025em 0 0 #ec2225, -0.025em -0.025em 0 #313f97,
        -0.025em -0.05em 0 #50c878;
    }
  }
  